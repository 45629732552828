import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Row, Space, Typography } from 'antd'
import { mdiEarth, mdiEmailOutline, mdiFacebook, mdiInstagram, mdiPhone } from '@mdi/js'
import classNames from 'classnames'
import SimpleLogo from '@/assets/images/simple_logo.png'
import LogoWhite from '@/assets/images/panerg-primary-w.png'
import MaterialIcon from '@/components/icon/MaterialIcon'
import { useBreakpoint } from '@/hooks/useBreakpoint'
import styles from './LayoutFooter.module.scss'
import { useDispatch } from 'react-redux'
import { useSelector } from '@/store'
import Loading from '../../Loading'

const LayoutFooter: FC = () => {
  const { t } = useTranslation()
  const isSmall = useBreakpoint()
  const dispatch = useDispatch()
  const { contacts } = useSelector(state => state.encontact)
  const { loading } = useSelector(state => state.loading.effects.encontact.getContact)

  useEffect(() => {
    if (!contacts) {
      dispatch.encontact.getContact()
    }
  }, [dispatch])

  return (
    <div
      className={classNames(styles.footer, {
        [styles.footerMobile]: isSmall
      })}
    >
      <Row align="middle" gutter={[32, 12]}>
        <Col xs={24} lg={12} xl={6}>
          <img
            src={LogoWhite}
            className={classNames(styles.footerLogo, {
              [styles.footerLogoMobile]: isSmall
            })}
          />
        </Col>
        <Col xs={12} md={12} lg={12} xl={6}>
          {loading ? (
            <Loading size={1} />
          ) : (
            <Space direction="vertical">
              <Typography.Text>Pannon Energiaközösség Nkft.</Typography.Text>
              <a href={contacts?.addressLink ?? undefined}>
                <MaterialIcon path={mdiEarth} className={styles.footerIcon} />
                <Typography.Text underline>${contacts?.address}</Typography.Text>
              </a>
              <a href={`mailto:${contacts?.email}`}>
                <MaterialIcon path={mdiEmailOutline} className={styles.footerIcon} />
                <Typography.Text underline>{contacts?.email}</Typography.Text>
              </a>
              <a href={`tel:${contacts?.phone}`}>
                <MaterialIcon path={mdiPhone} className={styles.footerIcon} />
                <Typography.Text underline>{contacts?.phone}</Typography.Text>
              </a>
            </Space>
          )}
        </Col>
        <Col xs={12} md={12} lg={6} xl={3}>
          <Space direction="vertical">
            <a href="https://pannek.hu">
              <Typography.Text underline>{t('footer.about')}</Typography.Text>
            </a>
            <a href="https://pannek.hu">
              <Typography.Text underline>{t('footer.advanteges')}</Typography.Text>
            </a>
          </Space>
        </Col>
        <Col xs={12} md={12} lg={6} xl={3}>
          <Space direction="vertical">
            <a href="https://pannek.hu">
              <Typography.Text underline>{t('footer.how-it-works')}</Typography.Text>
            </a>
            <a href="https://pannek.hu">
              <Typography.Text underline>{t('footer.faq')}</Typography.Text>
            </a>
          </Space>
        </Col>
        <Col xs={12} md={12} lg={6} xl={3}>
          {loading ? (
            <Loading size={1} />
          ) : (
            <Space direction="horizontal" size="middle">
              {contacts?.facebook && (
                <a href={contacts?.facebook} target="_blank" rel="noreferrer">
                  <MaterialIcon path={mdiFacebook} className={styles.socialIcon} size={1.5} />
                </a>
              )}

              {contacts?.instagram && (
                <a href={contacts?.instagram} target="_blank" rel="noreferrer">
                  <MaterialIcon path={mdiInstagram} className={styles.socialIcon} size={1.5} />
                </a>
              )}
            </Space>
          )}
        </Col>
        <Col xs={24} md={12} lg={6} xl={3}>
          <a href={t('simple-url')} target="_blank" rel="noreferrer">
            <img src={SimpleLogo} className={styles.simpleLogo} />
          </a>
        </Col>
      </Row>
    </div>
  )
}

export default LayoutFooter
