import { FC, ReactElement } from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { Layout } from 'antd'
import classNames from 'classnames'
import LayoutFooter from './components/LayoutFooter/LayoutFooter'
import styles from './PublicLayout.module.scss'

interface PublicLayoutProps {
  children: ReactElement
  className?: string
  contentClassName?: string
}

const PublicLayout: FC<PublicLayoutProps> = ({ children, className, contentClassName }) => {
  const GOOGLE_API_KEY = import.meta.env.VITE_GOOGLE_RECAPTCHA_KEY as string

  return (
    <Layout className={classNames(styles.publicLayout, className)}>
      <Layout.Content className={styles.layoutContent}>
        <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_API_KEY}>
          <div className={classNames(styles.content, contentClassName)}>{children}</div>
        </GoogleReCaptchaProvider>
        <LayoutFooter />
      </Layout.Content>
    </Layout>
  )
}

export default PublicLayout
